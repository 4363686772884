import BaseBean from "@/utils/BaseBean";

export interface IServiceStationTxlDataObj {
    utilInst:ClientServiceStationTxlUtil
    refMap:Map<string,any>
    provinceData:Array<any>
    cityData:Array<any>
    listData:Array<any>
    listDataBak:Array<any>
    fiterParam:any
    otherParams:any
    curBrand:number
}
export default class ClientServiceStationTxlUtil extends BaseBean{
    public dataObj:IServiceStationTxlDataObj;

    constructor(proxy:any,dataObj:IServiceStationTxlDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //初始化页面数据
    public async initPageData():Promise<any>{
        const loading = this.proxy.$loading({lock: true,text: '正在加载，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        let params=Object.assign({curBrand:this.dataObj.curBrand,flag:1},this.dataObj.fiterParam);
        let res=await this.utils.ToolsProviderApi.initServiceStationPageData(params);
        if(res.code && res.code=='0000' && res.data){
            let pageJsonData=JSON.parse(res.data);
            this.dataObj.provinceData=pageJsonData.provinceData;
            this.dataObj.cityData=pageJsonData.cityData;
            this.dataObj.otherParams.toolBrandData=pageJsonData.toolBrandData;
        }
        loading.close();
    }
    //过滤数据
    public async filerServiceStationTxl():Promise<any>{
        //tmd，如果查询条件全部为空，要把所有的数据给清除，哎
        if(!this.dataObj.fiterParam.brandId && !this.dataObj.fiterParam.filterKey
            && !this.dataObj.fiterParam.provinceId && !this.dataObj.fiterParam.cityId){
            this.dataObj.listData=[];
        }else{
            let params=Object.assign({curBrand:this.dataObj.curBrand,flag:1},this.dataObj.fiterParam);
            let res=await this.utils.ToolsProviderApi.clientQueryServiceStation(params);
            if(res.code='0000' && res.data)this.dataObj.listData=JSON.parse(res.data);
        }
    }
    //根据省份加载城市
    public async getCitiesByProvince(provinceId:string):Promise<any>{
        let res=await this.utils.ToolsProviderApi.clientGetCitiesByProvince({provinceId:provinceId});
        if(res.code=='0000')this.dataObj.cityData=res.data;
    }
}
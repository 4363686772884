import {ref, reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick, computed} from 'vue';
import ClientServiceStationTxlUtil ,{IServiceStationTxlDataObj} from './clientServiceStationTxlUtil';
export default defineComponent({
    name:'personCenter',
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            if(to.name=='clientSwm'){
                curProxyInst.curBrand=2;
            }else if(to.name=='clientServiceStationTxl'){
                curProxyInst.curBrand=4;//其实没有品牌4，这里表示显示所有的通讯录品牌
            }
            document.title = '服务网点查询';
        })
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IServiceStationTxlDataObj=reactive<IServiceStationTxlDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            provinceData:[],
            cityData:[],
            listData:[],
            listDataBak:[],
            fiterParam:{
                brandId:'',
                filterKey:'',
                provinceId:'',
                cityId:''
            },
            curBrand:1,
            otherParams:{
                toolBrandData:[]
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ClientServiceStationTxlUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.initPageData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'provinceId'==params.comboId){
                    return dataObj.provinceData
                }
                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData
                }
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.toolBrandData
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,type:string)=>{
            if('provinceId'==type){
                dataObj.fiterParam.cityId='';
                await dataObj.utilInst.getCitiesByProvince(newValue);
                nextTick(async ()=>{
                    await searchHandler();
                })
            }
            if('cityId'==type || 'brandId'==type){
                nextTick(async ()=>{
                    await searchHandler();
                })
            }
        }
        const searchHandler=async ()=>{
            await dataObj.utilInst.filerServiceStationTxl();
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,searchHandler
        }
    }
});